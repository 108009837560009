// COLOR VARIABLES
// Please do not create one-off colors before consulting this list.

// default brand colors
$blue: rgb(18, 89, 145);
$lightblue: lighten($blue, 25%);
$bluegrey: rgb(145, 182, 212);
$darkblue: rgb(4, 50, 84);
$yellow: rgb(252, 173, 15);
$black: rgb(14, 14, 14);
$midblack: rgb(29,29,29);
$softblack: rgb(41, 41, 41);
$lightblack: rgb(130, 130, 130);
$white: rgb(244, 244, 244);
$truewhite: rgb(255, 255, 255);
$grey: rgb(200, 200, 200);
$red: rgb(200, 31, 31);
$palered: lighten($red, 50%);