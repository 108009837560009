//-- Resets to vendor css --//

//-- magnific
.mfp-container {
  article, section {
    background-color: $white;
    color: $black;
    box-shadow: 0px 0px 7px rgba(0,0,0, 0.6);
    padding: 1em;
    margin: 8px auto;
    width: 96%;
    display: table;
    position: relative;

    @include breakpoint($medium) {
      width: 50rem;
      margin: 48px auto;

      // form default width, constrain #formResponse
      &#contact {
        width: 40rem;
      }
    }

    @include breakpoint($large) {
      width: 60rem;
    }

    article, section {
      box-shadow: none;
      margin: 0;
      padding: 0;
      max-width: none;
      display: block;
    }

    table, form { margin-bottom: 0px; }

    button.mfp-close {
      margin: 0;
      opacity: 1;
      color: $blue;

      // by default, it moves when clicked... we don't want that
      &:active {
        top: 0;
      }
    }
  }
}